import {Swiper, EffectFade} from 'swiper'

export default () => {
  const tags = document.querySelectorAll('.js-tarieven-tag')
  const swiper = new Swiper('.js-swiper-tarieven', {
    modules: [EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    effect: 'fade',
    loop: true,
    autoHeight: true
  })

  if (!tags.length) {
    return false
  }

  const setActiveTag = (tag) => {
    tag.classList.add('bg-brand-900', 'text-white')
    tag.classList.remove('bg-gray-300/20', 'text-black')
  }

  const unsetActiveTag = () => {
    tags.forEach(tag => {
      tag.classList.remove('bg-brand-900', 'text-white')
      tag.classList.add('bg-gray-300/20', 'text-black')
    })
  }

  swiper.on('slideChange', function () {
    unsetActiveTag()
    tags.forEach(tag => {
      if (Number(tag.getAttribute('data-slide-index')) === swiper.realIndex + 1) {
        setActiveTag(tag)
      }
    })
  })

  tags.forEach(tag => {
    const handleClick = (e) => {
      swiper.slideTo(e.target.getAttribute('data-slide-index'))
      unsetActiveTag()
      setActiveTag(e.target)
    }
    tag.addEventListener('click', handleClick)

    document.addEventListener('htmx:beforeRequest', () => {
      tag.removeEventListener('click', handleClick)
    })
  })

  document.addEventListener('htmx:beforeRequest', () => {
    swiper.destroy()
  })

  document.addEventListener('openPricingFeatureDetail', () => {
    swiper.update()
  })
}
