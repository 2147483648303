export default () => {
    const accordions = document.querySelectorAll('.js-accordion')
    if (accordions.length < 1) {
        return false
    }

    for (const accordion of accordions) {
        const terms = accordion.querySelectorAll('.js-accordion-term')
        const closeBtns = accordion.querySelectorAll('.js-accordion-close')

        if (terms.length) {

            for (const term of terms) {
                const handleAccordionClick = (e) => {
                    let closeOtherPanels = true
                    let currTerm = e.currentTarget
                    closeOtherPanels = currTerm.dataset.closeOther !== 'false'
                    let panel = currTerm.nextElementSibling

                    for (const anotherTerm of terms) {
                        if (anotherTerm !== currTerm && closeOtherPanels) {
                            let anotherPanel = anotherTerm.nextElementSibling
                            anotherPanel.style.maxHeight = '0px'
                            anotherTerm.classList.remove('is-active')
                        }
                    }

                    if (currTerm.classList.contains('is-active')) {
                        panel.style.maxHeight = '0px'
                        currTerm.classList.remove('is-active')
                    } else {
                        panel.style.maxHeight = panel.scrollHeight + 'px'
                        currTerm.classList.add('is-active')
                    }
                }

                term.addEventListener('click', handleAccordionClick)

                document.addEventListener('htmx:beforeRequest', () => {
                    term.removeEventListener('click', handleAccordionClick)
                })
            }

            if (closeBtns.length) {
                for (const btn of closeBtns) {
                    const handleAccordionBtnClick = (e) => {
                        let currBtn = e.currentTarget
                        const currTerm = currBtn.closest('.js-accordion').querySelector('.js-accordion-term')
                        const panel = currTerm.nextElementSibling

                        if (currTerm.classList.contains('is-active')) {
                            panel.style.maxHeight = '0px'
                            currTerm.classList.remove('is-active')
                        } else {
                            panel.style.maxHeight = panel.scrollHeight + 'px'
                            currTerm.classList.add('is-active')
                        }
                    }

                    btn.addEventListener('click', handleAccordionBtnClick)

                    document.addEventListener('htmx:beforeRequest', () => {
                        btn.removeEventListener('click', handleAccordionBtnClick)
                    })
                }
            }
        }
    }
}
