export default () => {
    const fadeButtons = document.querySelectorAll('.js-fade-content-btn')

    if (fadeButtons.length < 1) {
        return false
    }

    const handleClick = (e) => {
        const panel = e.target.closest('.js-fade-content')
        if (panel !== null) {
            const panelInner = panel.querySelector('.fade-content__inner')
            panelInner.style.maxHeight = panelInner.scrollHeight + 'px'
            panel.classList.add('is-active')
            document.dispatchEvent(new CustomEvent('openPricingFeatureDetail'))
        }
    }

    fadeButtons.forEach(btn => {
        btn.addEventListener('click', handleClick)
    })

    document.addEventListener('htmx:beforeRequest', () => {
        fadeButtons.forEach(btn => {
            btn.removeEventListener('click', handleClick)
        })
    })
}